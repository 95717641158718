import { Link, useNavigate, useParams } from 'react-router-dom'
import eye10 from '../../css/images/eye-10.svg'
import thumbsUp from '../../css/images/thumbs-up.svg'
import eye3 from '../../css/images/eye 3.svg'
import styled from '@emotion/styled'
import detailCss from '../../css/detail.module.css'
import { useEffect, useRef, useState } from 'react'
import $ from 'jquery';
import { useRecoilValue } from 'recoil'
import { getCookie, removeCookie, removeCookieOption } from '../../hooks/Cookie'
import axios from 'axios'
import URL from '../../constants/url'
import defaultImg  from '../../css/images/illustDefault.png'
import { apiServerState } from '../../constants/recoilData'
import Swal from 'sweetalert2'


const Novel = () => {

    let { novelid } = useParams();

    const modalRef = useRef();
    const token = getCookie("token");
    const navigate = useNavigate();

    const logout = () => {
        removeCookie("token");
        removeCookieOption("token", {path : "/"});
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        window.localStorage.clear();
        const token = getCookie("token");
        if (token !== undefined) {
            removeCookieOption("token", {path : "/"});
            navigate(URL.MAIN);
        } else {
            navigate(URL.MAIN);
        }
    }

    // 작품 신고 버튼(모달 오픈)
    const onClickModal = () => {
        $("#modalText").text("작품을 신고하시겠습니까?");
        $("#cancelBtn").css("display", "inline");
        $("#reportBtn").css("display", "inline");
        modalRef.current.style.display = 'block';
    }

    // 작품 신고 모달 취소 버튼
    const onClickCancel = () => {
        $("#modalText").text("작품을 신고하시겠습니까?");
        $("#cancelBtn").css("display", "inline");
        $("#reportBtn").css("display", "inline");
        modalRef.current.style.display = 'none';
    }

    // 작품 신고 모달 신고 버튼
    const onClickReport = () => {
        if (token === undefined) {
            navigate(URL.LOGIN);
        }

        const config = {
            headers: {
              "Content-Type": "application/json",
              "authorization": token,
            }
        };
        let form = { "novelId": parseInt(novelid) };

        axios
            .post(apiServer + "/api/aizac/report/report_novel", form, config)
            .then((response) => {
                if (response?.data?.success) {
                    $("#modalText").text("신고가 완료되었습니다.");
                    $("#cancelBtn").css("display", "none");
                    $("#reportBtn").css("display", "none");
                } else {
                    const msg = response?.data?.custMsg;
                    if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                        $("#modalText").text("다시 로그인 후 이용해주세요.");
                        $("#cancelBtn").css("display", "none");
                        $("#reportBtn").css("display", "none");
                        logout();
                    } else if (response?.data?.custMsg === "already reported") {
                        $("#modalText").text("중복 신고입니다.");
                        $("#cancelBtn").css("display", "none");
                        $("#reportBtn").css("display", "none");
                    } else {
                        $("#modalText").text("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                        $("#cancelBtn").css("display", "none");
                        $("#reportBtn").css("display", "none");
                    }
                    console.log(msg);
                }
            })
            .catch((e) => {
                alert(e.message);
                console.log(e);
                navigate(URL.ERROR, {state: {msg: e.message}});
            });

        
    }

    // 작품 신고 모달 닫기 함수
    const onClickCloseModal = () => {
        modalRef.current.style.display = 'none';
    }

    const [rowCnt, setRowCnt] = useState(10);
    const apiServer = useRecoilValue(apiServerState);
    const [episodesList, setEpisodesList] = useState([]);
    const [, setEpisodesList2] = useState([]);
    const [epi1Bool, setEpi1Bool] = useState(false);
    const [LastepisodeId, setLastEpisodeId] = useState(0);
    const [LastepisodepubState, SetLastepisodepubState] = useState("");
    const [isMounted, setIsMounted] = useState(false);
    const [isMountedEpi, setIsMountedEpi] = useState(false);
    const [novel, setNovel] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const [pubday, setPubDay] = useState([]);
    const [period, setPeriod] = useState(null);
    const [viewCnt, setViewCnt] = useState(null);

    useEffect(() => {
        const localData = localStorage.getItem("rowCnt");
        if(localData !== null) {
            setRowCnt(localData);
        }
        window.scrollTo({
            top: 0,
            behavior: 'instant'
        })
    }, [])

    useEffect(() => {
        const pubday2 = novel?.pubday;
        const viewCnt2 = novel?.viewCnt;
        const periodCopy = novel?.period !== null ? String(novel?.period) : null; 
        if(periodCopy !== null && periodCopy !== undefined && periodCopy !== "") {
            switch(periodCopy) {
                case "free" : {
                    setPeriod("자유연재");
                    break;
                }
                case "daily" : {
                    setPeriod("매일연재");
                    break;
                }
                case "weekly" : {
                    setPeriod("주말연재");
                    break;
                }
            }
        } else {
            setPeriod(periodCopy);
        }
        if(pubday2 !== null && pubday2 !== undefined && pubday2 !== "") {
            const data = pubday2.split(', ');
            var sortedDays = data.sort(function(a, b) {
                // 요일을 정렬하기 위해 영어로 된 요일을 비교
                var daysOrder = ["monday", "tuesday", "wednesday", "thursday", "friday", "Saturday", "sunday"];
                return daysOrder.indexOf(a) - daysOrder.indexOf(b);
            });
            let str = "";
            if (sortedDays.length > 0) {
                for (let i = 0; i < sortedDays.length; i++) {
                    if((i + 1) < sortedDays.length ) {
                        switch (sortedDays[i]) {
                            case "monday" : {
                                str += "월, ";
                                break;
                            }
                            case "tuesday" : {
                                str += "화, ";
                                break;
                            }
                            case "wednesday" : {
                                str += "수, ";
                                break;
                            }
                            case "thursday" : {
                                str += "목, ";
                                break;
                            }
                            case "friday" : {
                                str += "금, ";
                                break;
                            }
                            case "Saturday" : {
                                str += "토, ";
                                break;
                            }
                            case "sunday" : {
                                str += "일";
                                break;
                            }
                        }
                    } else {
                        switch (sortedDays[i]) {
                            case "monday" : {
                                str += "월";
                                break;
                            }
                            case "tuesday" : {
                                str += "화";
                                break;
                            }
                            case "wednesday" : {
                                str += "수";
                                break;
                            }
                            case "thursday" : {
                                str += "목";
                                break;
                            }
                            case "friday" : {
                                str += "금";
                                break;
                            }
                            case "Saturday" : {
                                str += "토";
                                break;
                            }
                            case "sunday" : {
                                str += "일";
                                break;
                            }
                        }
                    }
                }
            }
            setPubDay(str);
        } else {
            setPubDay(pubday2);
        }

        const num = parseInt(viewCnt2);
        if(num >= 1000) {
            const result = num / 1000;
            const roundedResult = result.toFixed(1);
    
            const str = roundedResult + "k";
            setViewCnt(str);
        } else {
            setViewCnt(num);
        }

    }, [novel])
    //const [synopsis, setSynopsis] = useState(null);
    //const [characters, setCharacters] = useState(null);

    // rowCnt 변경시 업데이트
    useEffect(() => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "authorization": token,
            }
        };
    
        const update = () => {

            const urlEpi = apiServer + "/api/aizac/episode/row_cnt_episodes?novelId=" + novelid + "&rowCnt=2000";
            const urlDetail = apiServer + "/api/asiac_novel/get_novel_detail?novelId=" + novelid;
            const urlUser = apiServer + "/api/asiac_login/get_my_info";
            
            const urlEpiCall = async () => {
                axios
                    .get(urlEpi, config)
                    .then((response) => {
                        if (response?.data?.success) {
                            const copy = response?.data?.data?.episodes;
                            const copy2 = [];
                            for (let i = 0; i < copy.length; i++) {
                                if (copy[i].pubState === "public") {
                                    copy2.push(copy[i]);
                                }
                            }
                            setEpisodesList(copy2);
                            setIsMountedEpi(true);
                        } else {
                            const msg = response?.data?.custMsg;
                            if (msg === "router err") {
                                alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                            } else {
                                alert(msg);
                            }
                            navigate(URL.MAIN);
                            console.log("로딩 실패");
                        }
                    })
                    .catch(e => {  // API 호출이 실패한 경우
                        alert(e.message);
                        console.log(e);
                        navigate(URL.ERROR, {state: {msg: e.message}});
                    });
            };
            const urlDetailCall = async () => {
                axios
                    .get(urlDetail, config)
                    .then((response) => {
                        if (response?.data?.success) {
                            if ((response.data.data.episodes).length !== 0) {
                                for (let i = 0; i < response.data.data.episodes.length; i++) {
                                    if (response?.data?.data?.episodes[i].pubState === "public") {
                                        setEpi1Bool(response?.data?.data?.episodes[i].bPurchased);
                                        setLastEpisodeId(response?.data?.data?.episodes[i].id);
                                        SetLastepisodepubState(response?.data?.data?.episodes[i].pubState);
                                        break;
                                    }
                                }
                            } else {
                                alert("비정상적인 접근입니다.");
                                navigate(-1);
                            }
                            setNovel(response?.data?.data?.novel);
                            setEpisodesList2(response?.data?.data?.episodes);
                            //setSynopsis(response.data.data.synopsis);
                            //setCharacters(response.data.data.characters);
                            setIsMounted(true);
                        } else {
                            const msg = response?.data?.custMsg;
                            if (msg === "invalid novel") {
                                alert("없는 소설 또는 비공개 소설 입니다.");
                            } else if (msg === "router err") {
                                alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                            } else {
                                alert(msg);
                            }
                            navigate(URL.MAIN);
                            console.log(response?.data?.custMsg);
                        }
                    })
                    .catch(e => {  // API 호출이 실패한 경우
                        alert(e.message);
                        console.log(e);
                        navigate(URL.ERROR, {state: {msg: e.message}});
                    });
            };
            const userInfo = async () => {

                await axios
                .get(urlUser, config)
                .then((response) => {
                    if (response?.data?.success) {
                        setUserInfo(response?.data?.data?.user);
                        setIsMounted(true);
                    } else {
                        const msg = response?.data?.custMsg;
                        if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                            alert("다시 로그인해주세요.");
                            logout();
                            navigate(URL.LOGIN);
                        } else if (msg === "router err") {
                            alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                            navigate(URL.MAIN);
                        } else if (msg === "invalid user") {
                            alert("다시 로그인해주세요.");
                            logout();
                            navigate(URL.LOGIN);
                        } else {
                            alert(msg);
                            navigate(URL.MAIN);
                        }
                        console.log("로딩 실패");
                        console.log(msg);
                    }
                })
                .catch(e => {  // API 호출이 실패한 경우
                    alert(e.message);
                    console.log(e);
                    navigate(URL.ERROR, {state: {msg: e}});
                });
            };
        
            urlEpiCall();
            urlDetailCall();
            if(token !== undefined) {
                userInfo();
            }
        };
        update();
    }, [rowCnt])

    // 더보기 버튼 클릭
    const onClickRowBtn = () => {
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        const row = parseInt(rowCnt) + 10; 
        setRowCnt(row);
    }

    // 첫화보기, 회차 상세보기 이동 버튼
    const onClickDetail = (novelId, sessionId, bPurchased, pubState) => {
        localStorage.setItem("rowCnt", rowCnt);
        if (pubState !== "public") {

            return;
        }
        if (token === undefined) {
            navigate(URL.LOGIN);
            return;
        }
        if (bPurchased) {
            navigate('/novel/session/' + novelId + '/' + sessionId)
        } else {
            if(novel?.proficiency !== "novice") {
                Swal.fire({
                    title : "작품 구매",
                    text: "유료소설은 1화 당 20코인이 소모됩니다",
                    showCancelButton: true,
                    confirmButtonColor: "#FF5F37",
                    cancelButtonColor: "#bdbdbd",
                    cancelButtonText: "취소",
                    confirmButtonText: "사용",
                    reverseButtons: true,
                }).then((result) => {
                    if (result.dismiss === Swal.DismissReason.confirm) {
                        if (token === undefined) {
                            navigate(URL.LOGIN);
                            return;
                        }
                        if(((isNaN(parseInt(userInfo?.coins)) ? 0 : parseInt(userInfo?.coins)) +
                        (isNaN(parseInt(userInfo?.freeCoin)) ? 0 : parseInt(userInfo?.freeCoin))) >= 20 || userInfo?.coinAuth === "unlimited") {
                            const config = {
                                headers: {
                                    "Content-Type": "application/json",
                                    "authorization": token,
                                }
                            };

                            const url = apiServer + "/api/asiac_novel/use_episode_coin"

                            const form = {
                                "novelId" : novelId,
                                "episodeId" : sessionId
                            }

                            axios
                                .post(url, form, config)
                                .then((response) => {
                                    if (response?.data?.success) {
                                        navigate('/novel/session/' + novelId + '/' + sessionId)
                                    } else {
                                        const msg = response?.data?.custMsg
                                        if(msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                                            alert("다시 로그인 후 이용해주세요.");
                                            logout();
                                        } else if (msg === "invalid novel") {
                                            alert("이용하실수 없는 소설입니다.");
                                        } else if (msg === "invalid episode") {
                                            alert("이용하실수 없는 회차입니다.")
                                        } else if (msg === "router err") {
                                            alert("서버 에러")
                                        } else {
                                            alert("구매 실패 : " + msg);
                                        }
                                        console.log(msg);
                                    }
                                })
                                .catch(e => {  // API 호출이 실패한 경우
                                    alert(e.message);
                                    console.log(e);
                                    navigate(URL.ERROR, {state: {msg: e.message}});
                                });
                            
                        } else {
                            Swal.fire({
                                title : "작품 구매",
                                text: "보유 코인이 부족합니다. 코인을 충전하시겠습니까?",
                                showCancelButton: true,
                                confirmButtonColor: "#FF5F37",
                                cancelButtonColor: "#bdbdbd",
                                cancelButtonText: "취소",
                                confirmButtonText: "코인 충전",
                                reverseButtons: true,
                            }).then((result) => {
                                if (result.dismiss === Swal.DismissReason.confirm) {
                                    navigate(URL.LIST_CHARGE_SHOP);
                                } 
                            });
                        }
                    } 
                });
            } else {
                navigate('/novel/session/' + novelId + '/' + sessionId)
            }


        }
        
    }

    const noImage = (e) => {
        e.target.src = defaultImg
    }

    return (
        <>
            <div id="wrap">
                {isMounted !== false ? <ContainerDiv id="container">
                    {novel && <TopInfoDiv className={detailCss.topInfo + " topInfo"}>
                        <BookImgDiv className={detailCss.bookImg + " bookImg"} >
                            {novel?.imgUrl !== null ? <BookImgImg src={novel?.imgUrl} alt={`book_${novel?.id}_img`} onError={noImage}/> : <BookImgImg src={defaultImg} alt='book_default_img'/>}
                        </BookImgDiv>
                        <BookTextDiv className={detailCss.bookText + " bookText"}>
                            <CateSpan className={detailCss.cate + " cate"}>{novel?.categoryName}</CateSpan>
                            <BookTitleDiv className={detailCss.bookTitle + " bookTitle"}>{(String(novel?.title)).trim()}</BookTitleDiv>
                            <SeriesInfoDiv className={detailCss.seriesInfo + " seriesInfo"}>
                                <AutorNameSpan className={detailCss.autorName + " autorName"}>{novel?.nickname} 작가</AutorNameSpan>
                                <DaySpan className={detailCss.day + " day"}>{`${novel?.period === null ? "" : period} ${novel?.pubday  === null ? "" : pubday}`}</DaySpan>
                                <BookNumSpan className={detailCss.bookNum + " bookNum"}>총 {episodesList !== null ? episodesList?.length : novel?.episodeCnt}화</BookNumSpan>
                            </SeriesInfoDiv>
                            <StoryDiv className={detailCss.story + " story"}>
                                {novel?.synopsis}
                            </StoryDiv>
                            <div className={detailCss.bookIcon + " bookIcon"}>
                                <BookIconViewSpan className={detailCss.view + " view"}>
                                    <img src={eye10} style={{marginBottom: "3px"}} alt='eye10'/>
                                    <BookIconSpan>조회수 {viewCnt}</BookIconSpan>
                                </BookIconViewSpan>
                                <BookIconRecommendSpan className={detailCss.recommend + " recommend"}>
                                    <img src={thumbsUp} alt='thumbsUp_img'/>
                                    <BookIconRecommendSpan>추천 {novel?.recommendCnt}</BookIconRecommendSpan>
                                </BookIconRecommendSpan>
                            </div>
                            <UserActionDiv className={detailCss.userAction + " userAction"}>
                            {novel?.episodeCnt !== "0" ?<ViewFirstButton type="button" className={detailCss.viewFirst + " viewFirst"} onClick={() => {onClickDetail(novel?.id, LastepisodeId, epi1Bool, LastepisodepubState)}}>
                                    <UserActionSpanSpan><DefaultALink>첫화 보기</DefaultALink></UserActionSpanSpan>
                                </ViewFirstButton>: <></>}
                                <DeclareBtnButton type="button" className={detailCss.declareBtn + " declareBtn"}  id="declareButton" onClick={onClickModal}>
                                    <UserActionSpanSpan>작품 신고</UserActionSpanSpan>
                                </DeclareBtnButton>
                            </UserActionDiv>

                            <ModalDiv id="myModal" className={detailCss.modal + " modal"} ref={modalRef} onClick={onClickCloseModal}>
                                <ModalContentDiv className={detailCss.modal_content + " modal_content"} onClick={(e) => e.stopPropagation()}>
                                    <ModalPP id="modalText">작품을 신고하시겠습니까?</ModalPP>
                                    <div className={detailCss.selectBtn + " selectBtn"}>
                                        <CalcelBtnButton id="cancelBtn" onClick={onClickCancel}>취소</CalcelBtnButton>
                                        <ReportBtnButton id="reportBtn" onClick={onClickReport}>신고</ReportBtnButton>
                                    </div>
                                </ModalContentDiv>
                            </ModalDiv>
                        </BookTextDiv>
                    </TopInfoDiv>}
                    <LineDiv className={detailCss.line + " line"}></LineDiv>
                    <EpisodeDiv className={detailCss.episode + " episode"}>
                        <EpiTitDiv className={detailCss.epiTit + " epiTit"}>회차정보 (<span>{episodesList !== null ? episodesList?.length : novel?.episodeCnt}</span>)</EpiTitDiv>
                        {isMountedEpi !== false ?<EpiTableTable className={detailCss.epiTable + " epiTable"}>
                            <tbody>
                            {episodesList && episodesList.map((epi, index) => {return(index < rowCnt ?<EpiTableTrTr key={"novel_" + epi?.id} onClick={() => {onClickDetail(novel?.id, epi?.id, epi?.bPurchased, epi?.pubState)}}>
                                <EpiTableNumTd className={detailCss.num + " num"}>
                                    <DefaultALink >ep.<span>{epi?.chapter}</span></DefaultALink>
                                </EpiTableNumTd>
                                <EpiTableTitleTd className={detailCss.title + " title"}>
                                    <DefaultALink >{epi?.title}</DefaultALink>
                                </EpiTableTitleTd>
                                <EpiTableViewTd className={detailCss.view + " view"}>
                                    <img src={eye3} alt='eye3_img'/>
                                    <span>조회수 {(parseInt(epi?.views) >= 1000 ? (parseInt(epi?.views) / 1000).toFixed(1) + "k" : parseInt(epi?.views))}</span>
                                </EpiTableViewTd>
                                <EpiTableDataTd className={detailCss.date + " date"}>
                                    {(epi?.createdAt+"").substring(0,10)}
                                </EpiTableDataTd>
                            </EpiTableTrTr> : <></>)})}
                            </tbody>
                        </EpiTableTable>: <></>}

                        {novel?.episodeCnt !== "0" ?  <LoadMoreBtnButton type="button" className={detailCss.loadMoreBtn + " loadMoreBtn"} onClick={onClickRowBtn}>
                            <LoadMoreBtnSpanSpan>더보기</LoadMoreBtnSpanSpan>
                        </LoadMoreBtnButton> : parseInt(novel.episodeCnt) !== 0 ? <LoadMoreBtnButton type="button" className={detailCss.loadMoreBtn + " loadMoreBtn"}>
                            <LoadMoreBtnSpanSpan>회차 정보가 없습니다</LoadMoreBtnSpanSpan>
                        </LoadMoreBtnButton> : <LoadMoreBtnButton type="button" className={detailCss.loadMoreBtn + " loadMoreBtn"} onClick={onClickRowBtn}>
                            <LoadMoreBtnSpanSpan>더보기</LoadMoreBtnSpanSpan>
                        </LoadMoreBtnButton> }
                               
                    </EpisodeDiv>          
                </ContainerDiv>: <></>}
            </div>
        </>
    );
}
export default Novel;

/** div 태그 .bookText css */
const BookTextDiv = styled.div`
    width: 950px;
    @media (min-width: 100px) and (max-width: 768px) {
        width: auto;
    }
`;

/** div 태그 .bookImg css */
const BookImgDiv = styled.div`
    min-width: 300px;
    height: 476px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @media (min-width: 360px) and (max-width: 768px) {
        width: 100%;
        height: 100%;
    }
    @media (min-width: 100px) and (max-width: 359px) {
        min-width: 100px;
        width: 100%;
        height: 100%;
    }
`;

/** div 태그 #container css */
const ContainerDiv = styled.div`
    margin: 0;
    box-sizing: border-box;
    word-break: keep-all;
    max-width:100%;
    width: auto;
    height: auto;
    padding: 0px 320px;
    padding-bottom: 100px;
    @media (max-width:1400px) {
        max-width:100%;
        width: auto;
        height: auto;
        padding: 0px 120px;
        padding-bottom: 80px;
    }
    @media(min-width:100px) and (max-width: 768px) {
        padding: 0 22px;
    }
`;

/** div 태그 .topInfo css */
const TopInfoDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: flex;
    align-items: flex-end;
    gap: 38px;
    padding-bottom: 32px;
    max-width: 100%;
    height: auto;
    margin-top: 46px;
    width: 1280px;
    @media(min-width:100px) and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
    }
`;

/** img 태그 .bookImg img css */
const BookImgImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 20px;
    @media(min-width:100px) and (max-width: 768px) {
        max-width: 100%;
        height: 100;
    }
`;

/** span 태그 .cate css */
const CateSpan = styled.span`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 16px;
    background-color: #22222a;
    color: #fff;
    padding: 6px 16px;
    border-radius: 20px;
    font-family: "Min Sans-Medium";
    @media(min-width:100px) and (max-width: 768px) {
        display: none;
    }
`;

/** div 태그 .bookTitle css */
const BookTitleDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: break-all;
    font-size: 38px;
    color: #050409;
    font-weight: 700;
    letter-spacing: -0.03em;
    margin-bottom: 8px;;
    margin-top: 12px;
    font-family: "Min Sans-bold";
    @media(min-width:100px) and (max-width: 768px) {
        font-size: 29px;
    }
    @media (max-width: 568px) {
        font-size: 22px;
    }
`;

/** div 태그 .seriesInfo css */
const SeriesInfoDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 18px;
    letter-spacing: -0.03em;
    padding-bottom: 10px;
    font-family: "Min Sans-Regular";
    @media(min-width:100px) and (max-width: 768px) {
        font-size: 13px;
    }
`;

/** span 태그 .autorName css */
const AutorNameSpan = styled.span`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    color: #22222a;
    padding-right: 8px;
    letter-spacing: -0.03em;
`;

/** span 태그 .day css */
const DaySpan = styled.span`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    color: #828284;
    padding-right: 8px;
    letter-spacing: -0.03em;
`;

/** span 태그 .bookNum css */
const BookNumSpan = styled.span`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    color: #828284;
    letter-spacing: -0.03em;
`;

/** div 태그 .story css */
const StoryDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: break-all;
    color: #22222a;
    line-height: 1.6;
    font-size: 16px;
    letter-spacing: -0.03em;
    padding-bottom: 16px;
    font-family: "Min Sans-Light";
    @media(min-width:100px) and (max-width: 768px) {
        font-size: 14px;
    }
`;

/** span 태그 .bookIcon > span .view css */
const BookIconViewSpan = styled.span`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    padding-right: 16px;
    font-family: "Min Sans-Regular";
    vertical-align: middle;
    margin-right: 5px;
    @media(min-width:100px) and (max-width: 768px) {
        font-size: 13px;
    }
`;

/** span 태그 .bookIcon > span .recommend css */
const BookIconRecommendSpan = styled.span`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    padding-right: 16px;
    font-family: "Min Sans-Regular";
    vertical-align: middle;
`;

/** span 태그 .bookIcon span css */
const BookIconSpan = styled.span`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    @media(min-width:100px) and (max-width: 768px) {
        font-size: 13px;
    }
`;

/** div 태그 .userAction css */
const UserActionDiv = styled.div`
    margin: 0;
    box-sizing: border-box;
    word-break: keep-all;
    padding-top: 22px;
`;

/** button 태그 .viewFirst css */
const ViewFirstButton = styled.button`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    background-color: #FF5F37;
    border: none;
    color:#fff;
    border: 1px solid #FF5F37;
    padding: 12px 40px;
    cursor: pointer;
    border-radius: 26px;
    margin-right: 10px;
    &:hover{
        background-color: #ff7957;
    }
    @media (min-width: 100px) and (max-width: 359px) {
        margin-bottom: 10px;
        margin-right: 0px;
    }
`;

/** button 태그 .declareBtn css */
const DeclareBtnButton = styled.button`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    background-color: #f6f6f6;
    color: #A8A8A9;
    border: 1px solid #f6f6f6;
    padding: 11px 20px;
    cursor: pointer;
    border-radius: 26px;
    margin-right: 6px;
    margin-left: 10px;
`;

/** span 태그 .userAction span css */
const UserActionSpanSpan = styled.span`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 15px;
    letter-spacing: -0.03em;
    font-family: "Min Sans-Medium";
    @media(min-width:100px) and (max-width: 768px) {
        font-size: 15px;
    }
    @media (min-width: 100px) and (max-width: 359px) {
        margin-bottom: 10px;
    }
`;

/** div 태그 .modal css */
const ModalDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); /* 배경색 및 투명도 설정 */
    z-index: 1; /* 모달을 다른 요소 위에 표시 */
`;

/** p 태그 .modal p css */
const ModalPP = styled.p`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 18px;
    font-family: "Min Sans-Regular";
    letter-spacing: -0.03em;
    color: #22222A;
    margin-bottom: 24px;
`;

/** a 태그 디폴트 css */
const DefaultALink = styled(Link)`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: break-all;
    text-decoration: none;
    color: inherit;
    border-top: none;
    @media (min-width: 100px) and (max-width: 359px) {
        margin-bottom: 10px;
    }
`;

/** div 태그 .modal-content css */
const ModalContentDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 30px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    text-align: center;
    border-radius: 20px;
    max-width: 330px;
`;

/** button 태그 #cancelBtn css */
const CalcelBtnButton = styled.button`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    padding: 12px 44px;
    margin: 5px;
    border: none;
    cursor: pointer;
    font-family: "Min Sans-Regular";
    font-size: 16px;
    border-radius: 8px;
    background-color: #f8f8f8;
    color: #22222A;
`;

/** button 태그 #reportBtn css */
const ReportBtnButton = styled.button`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    padding: 12px 44px;
    margin: 5px;
    border: none;
    cursor: pointer;
    font-family: "Min Sans-Regular";
    font-size: 16px;
    border-radius: 8px;
    background-color: #FF5F37;
    color: #fff;
`;

/** div 태그 .line css */
const LineDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    border: 1px solid #ededed;
`;

/** div 태그 .episode css */
const EpisodeDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    margin-top: 24px; 
    margin-bottom: 20px;
`;

/** div 태그 .epiTit css */
const EpiTitDiv = styled.div`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    color:#FF5F37;
    font-size: 18px;
    font-family: "Min Sans-Medium";
    letter-spacing: -0.03em;
    margin-bottom: 24px;
    @media(max-width:960px) {
        white-space: nowrap;
    }
    @media(min-width:100px) and (max-width: 768px) {
        font-size: 14px;
    }
`;

/** table 태그 .epiTable css */
const EpiTableTable = styled.table`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    border-collapse: collapse;
    width: 100%;
    font-family: "Min Sans-Regular";
    letter-spacing: -0.03em;
    & .num{
        width: 10%;
        text-align: center;
        color: #22222A;
        font-size: 18px;

    }
    & .title{
        width: 60%;
        color: #22222A;
        font-size: 18px;
        font-family: "Min Sans-Regular";
        cursor: pointer;

    }
    & .view{
        width: 12%;
        text-align: center;
        font-size: 16px;
        color: #A8A8A9;
    }
    & .date{
        width: 12%;
        text-align: center;
        font-size: 16px;
        color: #A8A8A9;
    }
    & tr/*:not(:last-of-type)*/ {
        border-bottom: 1px solid #ededed;
    }
    & tr:first-of-type {
        border-top: 1px solid #ededed;
    }
    & td{
        padding: 20px 0;
    }
    @media(max-width:1386px) {
        & .num{
        width: 10%;   
        }
        & .view{
            width: 20%;
        }
        & .date{
            width: 20%;
        }
    }
    @media(max-width:1400px) {
        & .num{
            width: 15%;   
        }
        & .view{
            width: 25%;
        }
        & .date{
            width: 25%;
        }
    }
    @media(max-width:960px) {
        white-space: nowrap;
    }
    @media(min-width:100px) and (max-width: 768px) {
        & .view {
            display: none;
        }
        & .date{
            display: none;
        }
        & .title{
            font-size: 14px;
        }
        & .num {
            font-size: 14px;
            width: 12%;
        }
    }
`;

/** tr 태그 .epiTable tr css */
const EpiTableTrTr = styled.tr`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    border-bottom: 1px solid #ededed;

`;

/** td 태그 .epiTable .num css */
const EpiTableNumTd = styled.td`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    width: 10%;
    text-align: center;
    color: #22222A;
    font-size: 18px;
    padding: 20px 0;
    @media(max-width:1386px) {
        width: 10%;
    }
    @media(max-width:1400px) {
        width: 15%;
    }
    @media(min-width:100px) and (max-width: 768px) {
        font-size: 14px;
        width: 12%;
    }
`;

/** td 태그 .epiTable .title css */
const EpiTableTitleTd = styled.td`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    width: 60%;
    color: #22222A;
    font-size: 18px;
    font-family: "Min Sans-Regular";
    cursor: pointer;
    padding: 20px 0;
    @media(min-width:100px) and (max-width: 768px) {
        font-size: 14px;
    }
`;

/** td 태그 .epiTable .view css */
const EpiTableViewTd = styled.td`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    width: 12%;
    text-align: center;
    font-size: 16px;
    color: #A8A8A9;
    padding: 20px 0;
    @media(max-width:1386px) {
        width: 20%;
    }
    @media(max-width:1400px) {
        width: 25%;
    }
    @media(min-width:100px) and (max-width: 768px) {
        display: none;
    }
`;

/** td 태그 .epiTable .date css */
const EpiTableDataTd = styled.td`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    width: 12%;
    text-align: center;
    font-size: 16px;
    color: #A8A8A9;
    padding: 20px 0;
    @media(max-width:1386px) {
        width: 20%;
    }
    @media(max-width:1400px) {
        width: 25%;
    }
    @media(min-width:100px) and (max-width: 768px) {
        display: none;
    }
`;

/** button 태그 .loadMoreBtn css */
const LoadMoreBtnButton = styled.button`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    width: 100%;
    margin-top: 20px;
    padding: 25px;
    border-radius: 20px;
    background-color: #F8F8F8;
    border: none;
    cursor: pointer;
`;

/** span 태그 .loadMoreBtn span css */
const LoadMoreBtnSpanSpan = styled.span`
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    word-break: keep-all;
    font-size: 16px;
    color: #22222A;
    letter-spacing: -2px;
    font-weight: 400;
    vertical-align: middle;
`;
