import { Link, useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import writeCss from '../../css/detail-write.module.css'
import { getCookie, removeCookie, removeCookieOption } from '../../hooks/Cookie';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import axios from 'axios';
import URL from '../../constants/url';
import { apiServerState } from '../../constants/recoilData';
import Swal from 'sweetalert2';


const NovelInfoNavSession = ({setEpisodeIdData, episode, novel}) => {
    const navigate = useNavigate();

    const { novelId, episodeId } = useParams();

    const token = getCookie("token");
    const [novelIdData] = useState(novelId);
    const [isMounted, setIsMounted] = useState(false);
    const apiServer = useRecoilValue(apiServerState);
    const [isRecommendation, setIsRecommendation] = useState(false);

    const [userInfo, setUserInfo] = useState(null);

    const logout = () => {
        removeCookie("token");
        removeCookieOption("token", {path : "/"});
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        window.localStorage.clear();
        const token = getCookie("token");
        if (token !== undefined) {
            removeCookieOption("token", {path : "/"});
            navigate(URL.MAIN);
        } else {
            navigate(URL.MAIN);
        }
    }

    // 추천 여부 에피소드 상세 정보 api 호출 후 셋팅, 유저정보 셋팅
    useEffect(() => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization" : token,
            }
        };
    
        const update = () => {
            const urlUser = apiServer + "/api/asiac_login/get_my_info";

            const urlEpi = apiServer + "/api/asiac_novel/get_episode_detail_simple?novelId=" + novelId + "&episodeId=" + episodeId;
            
            const urlEpiCall = async () => {
                axios
                    .get(urlEpi, config)
                    .then((response) => {
                        if (response?.data?.success ) {
                            const bRecommend = response?.data?.data?.novel?.bRecommend;
                            if (bRecommend === 1) {
                                setIsRecommendation(true);
                            } else {
                                setIsRecommendation(false);
                            }

                        } else {
                            const msg = response?.data?.custMsg;
                            if (msg === "invalid novel") {
                                alert("없는 소설 또는 비공개 소설입니다.");
                                navigate(URL.MAIN);
                            } else if (msg === "invalid episode") {
                                alert("없는 에피소드 또는 비공개 에피소드입니다.");
                                navigate(URL.MAIN);
                            } else if (msg === "need more coin") {
                                alert("코인이 부족합니다.");
                                navigate(URL.LIST_CHARGE_SHOP);
                            } else if (msg === "router err") {
                                alert("서버 에러");
                                navigate("/list/novel/" + novelId);
                            } else {
                                alert(msg);
                                navigate("/list/novel/" + novelId);
                            }
                            console.log("로딩 실패!");
                            console.log(msg);
                        }
                    })
                    .catch(e => {  // API 호출이 실패한 경우
                        alert(e.message);
                        console.log(e);
                        navigate(URL.ERROR, {state: {msg: e.message}});
                    });
            };


            urlEpiCall();
            
            const userInfo = async () => {

                await axios
                .get(urlUser, config)
                .then((response) => {
                    if (response?.data?.success) {
                        setUserInfo(response?.data?.data?.user);
                        setIsMounted(true);
                    } else {
                        const msg = response?.data?.custMsg;
                        if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                            alert("다시 로그인해주세요.");
                            logout();
                            navigate(URL.LOGIN);
                        } else if (msg === "router err") {
                            alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                            navigate(URL.MAIN);
                        } else if (msg === "invalid user") {
                            alert("다시 로그인해주세요.");
                            logout();
                            navigate(URL.LOGIN);
                        } else {
                            alert(msg);
                            navigate(URL.MAIN);
                        }
                        console.log("로딩 실패");
                        console.log(msg);
                    }
                })
                .catch(e => {  // API 호출이 실패한 경우
                    alert(e.message);
                    console.log(e);
                    navigate(URL.ERROR, {state: {msg: e.message}});
                });
            };
            if(token !== undefined) {
                userInfo();
            }
        };
        update();
    }, [novelIdData, episodeId, episode])

    // 이전화 버튼
    const onClickPrevEpisode = () => {
        if (episode?.prevEpisodeId === null) {
            alert("첫화입니다.");
            return;
        } else {
            if (novel?.proficiency !== "novice") {
                if (episode?.bPrevPurchased === 1) {
                    const url = '/novel/session/' + novel.id + '/' + episode?.prevEpisodeId;
                    navigate(url);
                    setEpisodeIdData(episode?.prevEpisodeId);
                } else {
                    Swal.fire({
                        title : "작품 구매",
                        text: "유료소설은 1화 당 20코인이 소모됩니다",
                        showCancelButton: true,
                        confirmButtonColor: "#FF5F37",
                        cancelButtonColor: "#bdbdbd",
                        cancelButtonText: "취소",
                        confirmButtonText: "구매",
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.dismiss === Swal.DismissReason.confirm) {
                            if (token === undefined) {
                                navigate(URL.LOGIN);
                                return;
                            }
                            if(((isNaN(parseInt(userInfo?.coins)) ? 0 : parseInt(userInfo?.coins)) +
                            (isNaN(parseInt(userInfo?.freeCoin)) ? 0 : parseInt(userInfo?.freeCoin))) >= 20 || userInfo?.coinAuth === "unlimited") {
                                const config = {
                                    headers: {
                                        "Content-Type": "application/json",
                                        "authorization": token,
                                    }
                                };
    
                                const url = apiServer + "/api/asiac_novel/use_episode_coin"
    
                                const form = {
                                    "novelId" : novel.id,
                                    "episodeId" : episode.prevEpisodeId
                                }
    
                                axios
                                    .post(url, form, config)
                                    .then((response) => {
                                        if (response?.data?.success) {
                                            navigate('/novel/session/' + novel.id + '/' + episode.prevEpisodeId)
                                            setEpisodeIdData(episode.prevEpisodeId);
                                        } else {
                                            const msg = response?.data?.custMsg
                                            if(msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                                                alert("다시 로그인 후 이용해주세요.");
                                                logout();
                                            } else if (msg === "invalid novel") {
                                                alert("이용하실수 없는 소설입니다.");
                                            } else if (msg === "invalid episode") {
                                                alert("이용하실수 없는 회차입니다.")
                                            } else if (msg === "router err") {
                                                alert("서버 에러")
                                            } else {
                                                alert("구매 실패 : " + msg);
                                            }
                                            console.log(msg);
                                        }
                                    })
                                    .catch(e => {  // API 호출이 실패한 경우
                                        alert(e.message);
                                        console.log(e);
                                        navigate(URL.ERROR, {state: {msg: e.message}});
                                    });
                            } else {
                                Swal.fire({
                                    title : "작품 구매",
                                    text: "보유 코인이 부족합니다. 코인을 충전하시겠습니까?",
                                    showCancelButton: true,
                                    confirmButtonColor: "#FF5F37",
                                    cancelButtonColor: "#bdbdbd",
                                    cancelButtonText: "취소",
                                    confirmButtonText: "코인 충전",
                                    reverseButtons: true,
                                }).then((result) => {
                                    if (result.dismiss === Swal.DismissReason.confirm) {
                                        navigate(URL.LIST_CHARGE_SHOP);
                                    } 
                                });
                            }
                        } 
                    });
                }
            } else {
                const url = '/novel/session/' + novel.id + '/' + episode?.prevEpisodeId;
                navigate(url);
                setEpisodeIdData(episode?.prevEpisodeId);
            }
            
        }
    }
    // 다음화 버튼
    const onClickNextEpisode = () => {
        if (episode?.nextEpisodeId === null) {
            alert("마지막화입니다.");
            return;
        } else {
            if (novel?.proficiency !== "novice") {
                if (episode?.bNextPurchased === 1) {
                    const url = '/novel/session/' + novel.id + '/' + episode?.nextEpisodeId;
                    navigate(url);
                    setEpisodeIdData(episode?.nextEpisodeId);
                } else {
                    Swal.fire({
                        title : "작품 구매",
                        text: "유료소설은 1화 당 20코인이 소모됩니다",
                        showCancelButton: true,
                        confirmButtonColor: "#FF5F37",
                        cancelButtonColor: "#bdbdbd",
                        cancelButtonText: "취소",
                        confirmButtonText: "구매",
                        reverseButtons: true,
                    }).then((result) => {
                        if (result.dismiss === Swal.DismissReason.confirm) {
                            if (token === undefined) {
                                navigate(URL.LOGIN);
                                return;
                            }
                            if(((isNaN(parseInt(userInfo?.coins)) ? 0 : parseInt(userInfo?.coins)) +
                            (isNaN(parseInt(userInfo?.freeCoin)) ? 0 : parseInt(userInfo?.freeCoin))) >= 20 || userInfo?.coinAuth === "unlimited") {
                                const config = {
                                    headers: {
                                        "Content-Type": "application/json",
                                        "authorization": token,
                                    }
                                };
    
                                const url = apiServer + "/api/asiac_novel/use_episode_coin"
    
                                const form = {
                                    "novelId" : novel.id,
                                    "episodeId" : episode.nextEpisodeId
                                }
    
                                axios
                                    .post(url, form, config)
                                    .then((response) => {
                                        if (response?.data?.success) {
                                            navigate('/novel/session/' + novel.id + '/' + episode.nextEpisodeId)
                                            setEpisodeIdData(episode.nextEpisodeId);
                                        } else {
                                            const msg = response?.data?.custMsg
                                            if(msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                                                alert("다시 로그인 후 이용해주세요.");
                                                logout();
                                            } else if (msg === "invalid novel") {
                                                alert("이용하실수 없는 소설입니다.");
                                            } else if (msg === "invalid episode") {
                                                alert("이용하실수 없는 회차입니다.")
                                            } else if (msg === "router err") {
                                                alert("서버 에러")
                                            } else {
                                                alert("구매 실패 : " + msg);
                                            }
                                            console.log(msg);
                                        }
                                    })
                                    .catch(e => {  // API 호출이 실패한 경우
                                        alert(e.message);
                                        console.log(e);
                                        navigate(URL.ERROR, {state: {msg: e.message}});
                                    });
                            } else {
                                Swal.fire({
                                    title : "작품 구매",
                                    text: "보유 코인이 부족합니다. 코인을 충전하시겠습니까?",
                                    showCancelButton: true,
                                    confirmButtonColor: "#FF5F37",
                                    cancelButtonColor: "#bdbdbd",
                                    cancelButtonText: "취소",
                                    confirmButtonText: "코인 충전",
                                    reverseButtons: true,
                                }).then((result) => {
                                    if (result.dismiss === Swal.DismissReason.confirm) {
                                        navigate(URL.LIST_CHARGE_SHOP);
                                    } 
                                });
                            }
                        } 
                    });
                }
            } else {
                const url = '/novel/session/' + novel?.id + '/' + episode?.nextEpisodeId;
                navigate(url);
                setEpisodeIdData(episode?.nextEpisodeId);
            }

        }
    }

    // 백 애로우 버튼 클릭
    const onClickBack = () => {
        navigate(`/list/novel/${novelId}`);
    }

    // 추천하기 버튼
    const onClickRecommend = () => {
        if (isRecommendation === false) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization" : token,
                }
            };
    
            const update = () => {
    
                const urlEpi = apiServer + "/api/aizac_detail/recommend";
                const form = {"novelId" : novel.id};
                
                const urlRecommendCall = async () => {
                    axios
                        .post(urlEpi, form, config)
                        .then((response) => {
                            if (response?.data?.success) {
                                setIsRecommendation(true);
                                alert("추천하였습니다.");
                                return;
                            } else {
                                const msg = response?.data?.custMsg;
                                if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                                    alert("다시 로그인 후 이용해주세요.");
                                    logout();
                                } else {
                                    alert(`사용 요청이 많습니다. 잠시후 다시 이용해 주세요.`);
                                }
                                console.log(msg);
                            }
                        })
                        .catch(e => {  // API 호출이 실패한 경우
                            alert(e.message);
                            console.log(e);
                            navigate(URL.ERROR, {state: {msg: e.message}});
                        });
                };
    
                urlRecommendCall();
                
            };
            update();
        } else {
            alert("추천한 작품입니다.");
        }
    }

    // 회차 리스트 버튼
    const onClickepisodeList = () => {
        const url = '/novel/detail/' + novel?.id + '/' + episodeId;
        navigate(url);
    }


    return (
        <>
            <InfoNavSection className={writeCss.infoNav + " infoNav"}>
                {isMounted !== false ? <TopInfoDiv className={writeCss.topInfo + " topInfo"}>
                    <EpiInfoDiv className={writeCss.epiInfo + " epiInfo"}>
                        <div className={writeCss.back + " back"} onClick={onClickBack}>
                            <DefaultALink ><BackI className="fa-solid fa-arrow-left" style={{textDecoration: "none", color: "inherit"}}></BackI></DefaultALink> 
                        </div>
                        <EpiNumDiv className={writeCss.epiNum + " epiNum"}>ep.{episode?.chapter}</EpiNumDiv>
                        {(String(episode?.title)).length < 40 ? <EpiTitleDiv className={writeCss.epiTitle + " epiTitle"}>{(String(episode?.title)).trim()}</EpiTitleDiv> : 
                        <EpiTitleDiv className={writeCss.epiTitle + " epiTitle"}>{((String(episode?.title)).trim()).substring(0,39) + "..."}</EpiTitleDiv>}
                    </EpiInfoDiv>
                    <UserSelectDiv className={writeCss.userSelect + " userSelect"}>
                        <UserSelectButton type="button" className={writeCss.writeList + " writeList"} style={{width: "120px"}} onClick={onClickepisodeList}>
                            <DefaultALink style={{textDecoration: "none", color: "inherit"}}>회차 리스트</DefaultALink>
                        </UserSelectButton>
                        <UserSelectButton type="button" className={writeCss.previous + " previous"} onClick={onClickPrevEpisode}><DefaultALink>이전화</DefaultALink></UserSelectButton>
                        <UserSelectNextButton type="button" className={writeCss.next + " next"} onClick={onClickNextEpisode}><DefaultALink >다음화</DefaultALink></UserSelectNextButton>
                        {isRecommendation === false ?<UserSelectRecommandButton type="button" className={writeCss.recommand + " recommand"} id="recommandButton" onClick={onClickRecommend}><DefaultALink >추천하기</DefaultALink></UserSelectRecommandButton>
                         : <UserSelectRecommandButton style={{backgroundColor: "#ff5f37", color: "#fff"}} type="button" className={writeCss.recommand + " recommand"} id="recommandButton" onClick={onClickRecommend}><DefaultALink >추천하기</DefaultALink></UserSelectRecommandButton>}
                        <UserSelectCommentButton type="button" className={writeCss.comment + " comment"}><DefaultALink >댓글쓰기</DefaultALink></UserSelectCommentButton>
                    </UserSelectDiv>
                </TopInfoDiv> : <></>}
            </InfoNavSection>
        </>
    );
}
export default NovelInfoNavSession;

/** section 태그 .infoNav css */
const InfoNavSection = styled.section`
    border-bottom: 1px solid #EDEDED;
    position: sticky;
    top: 0;
    background-color: #fff;
`;

/** div 태그 .topInfo css */
const TopInfoDiv = styled.div`
    display: flex;
    height: 66px;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    margin: 0 auto;
    padding: 0 320px;
    @media(max-width: 1400px) {
        padding: 0px 120px;
    }
    @media(min-width:100px) and (max-width: 768px) {
        padding:0 18px;
        height: 50px;
    }
`;

/** div 태그 .epiInfo css */
const EpiInfoDiv = styled.div`
    display: flex;
    font-family: "Min Sans-bold";
    color: #22222A;
    @media(max-width: 1400px) {
        min-width: 100px;
        text-overflow: ellipsis;
    }
    @media(max-width: 568px) {
        font-size: 15px;
    }
`;

/** i 태그 .back i css */
const BackI = styled.i`
    scale: 1.2;
    cursor: pointer;
    word-break: break-all;
`;

/** div 태그 .epiNum css */
const EpiNumDiv = styled.div`
    padding-right:18px;
    padding-left: 20px;
    @media(min-width:100px) and (max-width: 768px) {
        padding: 0 12px;
    }
`;

/** div 태그 .epiTitle css */
const EpiTitleDiv = styled.div`
    word-break: break-all;
    @media(max-width: 1400px) {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

/** div 태그 .userSelect css */
const UserSelectDiv = styled.div`
    display: flex;
    @media(min-width:100px) and (max-width: 768px) {
        display: flex;
    }
`;

/** button 태그 .userSelect button css */
const UserSelectButton = styled.button`
    font-size: 15px;
    font-family: "Min Sans-Medium";
    color: #22222a;
    width: 100px;
    height: 36px;
    background-color: #F6F6F6;
    border: none;
    border-radius: 21px;
    margin-left: 8px;
    letter-spacing: -0.02em;
    cursor: pointer;
    @media (min-width: 100px) and (max-width: 768px) {
        display: none;
    }
`;
/** button 태그 .userSelect .next button css */
const UserSelectNextButton = styled.button`
    font-size: 15px;
    font-family: "Min Sans-Medium";
    color: #22222a;
    width: 100px;
    height: 36px;
    background-color: #F6F6F6;
    border: none;
    border-radius: 21px;
    margin-left: 8px;
    letter-spacing: -0.02em;
    cursor: pointer;
    @media(max-width: 1400px) {
        margin-right: 10px;
    }
`;
/** button 태그 .userSelect .comment button css */
const UserSelectCommentButton = styled.button`
    font-size: 0;
    background-image: url('../css/images/message.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 36px;
    height: 36px;
    margin-left: 12px;
    font-family: "Min Sans-Medium";
    color: #22222a;
    background-color: #F6F6F6;
    border: none;
    border-radius: 21px;
    margin-left: 8px;
    letter-spacing: -0.02em;
    cursor: pointer;
    @media (min-width: 100px) and (max-width: 1400px) {
        display: none;
    }
`;
/** button 태그 .userSelect .recommand button css */
const UserSelectRecommandButton = styled.button`
    font-size: 15px;
    font-family: "Min Sans-Medium";
    width: 100px;
    height: 36px;
    border-radius: 21px;
    margin-left: 8px;
    letter-spacing: -0.02em;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #FF5F37;
    color: #FF5F37;
    &:hover {
        background-color: #ff5f37;
        color: #fff;
    }
    &:active{
        background-color: #ff5f37;
        color: #fff;
    }
    @media(max-width: 1400px) {
        font-size: 0;
        border: none;
        background-image: url('../css/images/ddabong.png');
        background-size: contain;
        background-repeat: no-repeat;
        width: 36px;
        height: 36px;
    }
    @media (min-width: 100px) and (max-width: 768px) {
        display: none;
    }
`;

/** a 태그 디폴트 css */
const DefaultALink = styled(Link)`
    text-decoration: none;
    color: inherit;
`;