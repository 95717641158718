import logoLImg from '../../css/images/logo-l2.png'
import CreateNovelLeftAsideCharacter from "../../components/common/CreateNovelLeftAsideCharacter";
import createNovel5Css from '../../css/createNovel5.module.css'
import styled from "@emotion/styled";
import { useNavigate } from 'react-router';
import { useEffect, useRef, useState } from "react";
import URL from "../../constants/url";
import { useRecoilValue } from 'recoil';
import { apiServerState } from '../../constants/recoilData';
import { getCookie, removeCookie, removeCookieOption } from '../../hooks/Cookie';
import axios from 'axios';
import Swal from 'sweetalert2';
import LeftAsideNavMenu from 'components/common/LeftAsideNavMenu';


// 글쓰기5 페이지 좌측 사이드 컴포넌트
const CreateNovelLeftAside = ({novelId, characters, isUpdateBool, isUpdate, setIsUpdate}) => {

    const navigate = useNavigate();

    const barsIcon = useRef();
    const xmarkIcon = useRef();
    const navMenu = useRef();
    const nnavMenu = useRef();

    // 햄버거 bars 버튼
    const barsIconCilck = () => {
        barsIcon.current.style.display = "none";
        nnavMenu.current.style.display = "none";
        xmarkIcon.current.style.display = "block";
        navMenu.current.style.display = "block";
    }

    // X 마크 버튼
    const xmarkIconClick = () => {
        xmarkIcon.current.style.display = "none";
        nnavMenu.current.style.display = "block";
        barsIcon.current.style.display = "block";
        navMenu.current.style.display = "none";
    }

    // 로고 클릭
    const onClickLogo = () => {
        navigate(URL.MAIN);
    }

    // 보유 코인 클릭
    const onClickCoin = () => {
        navigate(URL.LIST_CHARGE_SHOP);
    }

    const apiServer = useRecoilValue(apiServerState);

    const [isMounted, setIsMounted] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [episodeList, setEpisodeList] = useState([]);

    const logout = () => {
        removeCookie("token");
        removeCookieOption("token", {path : "/"});
        localStorage.removeItem("pageData");
        localStorage.removeItem("pageDataSynop");
        localStorage.removeItem("categoryNotice");
        localStorage.removeItem("categoryPay");
        localStorage.removeItem("myPicturePageData");
        localStorage.removeItem("rowCnt");
        window.localStorage.clear();
        const token = getCookie("token");
        if (token !== undefined) {
            removeCookieOption("token", {path : "/"});
            navigate(URL.MAIN);
        } else {
            navigate(URL.MAIN);
        }
    }

    // 최초 랜더링시 에피소드 리스트, 유저정보 셋팅
    useEffect(() => {
        const token = getCookie("token");
        if (token === undefined) {
            navigate(URL.MAIN);
        } else {
            const config = {
                headers: {
                  "Content-Type": "application/json",
                  "authorization": token,
                }
            };
        
            const update = () => {
                const url = apiServer + "/api/asiac_novel/get_episode_list?novelId=" + novelId;
                const urlUser = apiServer + "/api/asiac_login/get_my_info";
            
                const verteran = async () => {
                    await axios
                    .get(url, config)
                    .then((response) => {
                        if (response?.data?.success ) {
                            setEpisodeList(response?.data?.data?.episodes)
                        } else {
                            const msg = response?.data?.custMsg;
                            if (msg === "invalid novel") {
                                alert("없는 소설 또는 비공개 소설입니다.");
                            } else if (msg === "router err") {
                                alert("서버 에러");
                            } else if (msg === "invalid user") {
                                alert("다시 로그인해주세요.");
                                logout();
                                navigate(URL.LOGIN);
                            } else {
                                alert(msg);
                            }
                            navigate(URL.MAIN);
                            console.log(msg);
                            console.log("로딩 실패");
                        }
                    })
                    .catch(e => {  // API 호출이 실패한 경우
                        alert(e.message);
                        console.log(e);
                        navigate(URL.ERROR, {state: {msg: e.message}});
                    });
                };

                const userInfo = async () => {
                    await axios
                    .get(urlUser, config)
                    .then((response) => {
                        if (response?.data?.success) {
                            setUserInfo(response?.data?.data?.user);
                            setIsMounted(true);
                        } else {
                            const msg = response?.data?.custMsg;
                            if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                                alert("다시 로그인해주세요.");
                                logout();
                                navigate(URL.LOGIN);
                            } else if (msg === "router err") {
                                alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                                navigate(URL.MAIN);
                            } else {
                                alert(msg);
                                navigate(URL.MAIN);
                            }
                            console.log("로딩 실패");
                            console.log(msg);
                        }
                    })
                    .catch(e => {  // API 호출이 실패한 경우
                        alert(e.message);
                        console.log(e);
                        navigate(URL.ERROR, {state: {msg: e.message}});
                    });
                };
            
                verteran();
                if(token !== undefined) {
                    userInfo();
                }
            };
            update();
        }
        
    }, []);


    // 에피소드 클릭
    const onClickUrl = (epiId) => {
        navigate('/create-novel/' + novelId + "/" + epiId);
        setIsUpdate(!isUpdate);
    }

    // 새로운 회차쓰기 버튼
    const onClickNewEpisode = () => {
        navigate('/create-novel/' + novelId + "/0");
        setIsUpdate(!isUpdate);
    }

    // 작품 정보 수정 버튼
    const onClickModify = () => {
        navigate('/modify/novel/' + novelId);
    }

    // isUpdateBool 변경시 유저정보 업데이트(api 코인 차감시 유저정보 업데이트 용도)
    useEffect(() => {
        const token = getCookie("token");
        if (token === undefined) {
            navigate(URL.MAIN);
        } else {
            const config = {
                headers: {
                  "Content-Type": "application/json",
                  "authorization": token,
                }
            };
        
            const update = () => {
                const urlUser = apiServer + "/api/asiac_login/get_my_info";

                const userInfo = async () => {
                    await axios
                    .get(urlUser, config)
                    .then((response) => {
                        if (response?.data?.success) {
                            setUserInfo(response?.data?.data?.user);
                            setIsMounted(true);
                        } else {
                            const msg = response?.data?.custMsg;
                            if (msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                                alert("다시 로그인해주세요.");
                                logout();
                                navigate(URL.LOGIN);
                            } else if (msg === "router err") {
                                alert("사용 요청이 많습니다. 잠시후 다시 이용해 주세요.");
                                navigate(URL.MAIN);
                            } else if (msg === "invalid user") {
                                alert("다시 로그인해주세요.");
                                logout();
                                navigate(URL.LOGIN);
                            } else {
                                alert(msg);
                                navigate(URL.MAIN);
                            }
                            console.log("로딩 실패");
                            console.log(msg);
                        }
                    })
                    .catch(e => {  // API 호출이 실패한 경우
                        alert(e.message);
                        console.log(e);
                        navigate(URL.ERROR, {state: {msg: e.message}});
                    });
                };
                
                if(token !== undefined) {
                    userInfo();
                }
            };
            update();
        }
    }, [isUpdateBool])


    // 작품 삭제 버튼
    const onClickDeleteNovel = (novelId) => {

        const confirmationMessage =
            "소중한 작품을 정말로 삭제하시겠습니까?";

        Swal.fire({
            title: "삭제 안내",
            text: confirmationMessage,
            showCancelButton: true,
            confirmButtonColor: "#FF5F37",
            cancelButtonColor: "#bdbdbd",
            cancelButtonText: "취소",
            confirmButtonText: "삭제하기",
            reverseButtons: true,
        }).then((result) => {
            if (result.dismiss === Swal.DismissReason.confirm) {
                const token = getCookie("token");

                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        "authorization": token,
                    }
                };
                const form = {"novelId" : novelId};
        
                axios
                    .post(apiServer + "/api/asiac_novel/delete_novel", form, config)
                    .then((response) => {
                        if (response?.data?.success ) {
                            alert(`집필 내용을 삭제하였습니다.`);
                            setIsUpdate(!isUpdate);
                            navigate(URL.CREATE_MAIN);
                        } else {
                            const msg = response?.data?.custMsg;
                            if(msg === "" || msg === "no jwt token" || msg === "invalid jwt") {
                                alert("다시 로그인 후 진행해주세요.");
                                logout();
                            } else if (msg === "invalid data") {
                                alert("비정상적인 접근입니다.");
                                navigate(URL.CREATE_MAIN);
                            } else if (msg === "router failed") {
                                alert("서버 에러");
                                navigate(URL.CREATE_MAIN);
                            } else {
                                alert(response?.data?.custMsg);
                            }
                            console.log(msg);
                        }
                    })
                    .catch((e) => {
                        alert(e.message);
                        console.log(e);
                        navigate(URL.ERROR, {state: {msg: e.message}});
                    });
            }
        });
    }

    return (
        <>
            {isMounted === true ? <LeftAsideContentDiv className={createNovel5Css.content + " content"}>
                <LeftAsideTopDiv className={createNovel5Css.top + " top"}>
                    <nav className={createNovel5Css.nav + " nav"} style={{cursor : "pointer"}}>
                        <i
                            className={createNovel5Css.fa_solid + " " + createNovel5Css.fa_bars + " fa_solid fa_bars fa-solid fa-bars"}
                            style={{color : "#ffffff", fontSize: "22px", width: "19.5px"}}
                            id="bars-icon"
                            ref={barsIcon}
                            onClick={barsIconCilck}
                        ></i>
                        <i
                            className={createNovel5Css.fa_solid + " " +  createNovel5Css.fa_xmark + " fa_solid fa_xmark fa-solid fa-xmark"}
                            style={{color: "#ffffff", fontSize: "22px", width: "19.5px", display : "none"}}
                            id="xmark-icon"
                            ref={xmarkIcon}
                            onClick={xmarkIconClick}
                        ></i>
                    </nav>
                    <div className={createNovel5Css.logo + " logo"} id="logo" style={{height : "24px", cursor: "pointer"}} onClick={onClickLogo}>
                        <img src={logoLImg} style={{height : "100%"}} alt='logo_img'/>
                    </div>
                </LeftAsideTopDiv>
                <NavMenuDiv className={createNovel5Css.nav_menu + " nav_menu nav-menu"} id="nav-menu" ref={navMenu}>
                    <LeftAsideNavMenu/>
                </NavMenuDiv>
                <div id="nnav-menu" ref={nnavMenu}>
                    <ProfileDiv className={createNovel5Css.profile + " profile"}>
                        <TopProfileDiv className={createNovel5Css.topProfile + " topProfile"}>
                            <p className={createNovel5Css.level + " level"} style={{marginBottom : "0"}}>Lv.{userInfo?.level}</p>
                            <p className={createNovel5Css.name + " name"} style={{marginBottom : "0"}}>{userInfo?.nickname}</p>
                        </TopProfileDiv>
                        <BottomProfileDiv className={createNovel5Css.bottomProfile + " bottomProfile"} onClick={onClickCoin}>
                            <CoinDiv className={createNovel5Css.coin + " coin"}>
                                <p style={{marginBottom : "0"}}>보유코인 :</p>
                                <BottomProfileRightP className={createNovel5Css.right + " right"}>{parseInt(userInfo?.coin) !== null ? parseInt(userInfo?.coin) : 0}c</BottomProfileRightP>
                            </CoinDiv>
                            <CoinDiv className={createNovel5Css.coin + " coin"}>
                                <p style={{marginBottom : "0"}}>보유포인트 :</p>
                                <BottomProfileRightP className={createNovel5Css.right + " right"}>{parseInt(userInfo?.freeCoin) !== null ? parseInt(userInfo?.freeCoin) : 0}p</BottomProfileRightP>
                            </CoinDiv>
                        </BottomProfileDiv>
                    </ProfileDiv>
                    <div className={createNovel5Css.my + " " + createNovel5Css.Write + " my Write"}>
                        <MyTitDiv className={createNovel5Css.tit + " tit"}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="1em"
                                viewBox="0 0 576 512"
                                style={{fill : "#bcbcbf"}}>
                                <path
                                    d="M384 480h48c11.4 0 21.9-6 27.6-15.9l112-192c5.8-9.9 5.8-22.1 .1-32.1S555.5 224 544 224H144c-11.4 0-21.9 6-27.6 15.9L48 357.1V96c0-8.8 7.2-16 16-16H181.5c4.2 0 8.3 1.7 11.3 4.7l26.5 26.5c21 21 49.5 32.8 79.2 32.8H416c8.8 0 16 7.2 16 16v32h48V160c0-35.3-28.7-64-64-64H298.5c-17 0-33.3-6.7-45.3-18.7L226.7 50.7c-12-12-28.3-18.7-45.3-18.7H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H87.7 384z"
                                />
                            </svg>
                            <TitPP>등장인물</TitPP>
                        </MyTitDiv>
                        <ListGroupDiv className={createNovel5Css.list_group + " list_group list-group"}>
                            {characters && characters.map((character) => {return(<CreateNovelLeftAsideCharacter character={character} key={"character" + character?.id}/>)})}
                        </ListGroupDiv>
                    </div>
                    <div className={createNovel5Css.my + " " + createNovel5Css.Write + " my Write"}>
                        <div className={createNovel5Css.tit + " tit"}>
                            <svg
                                width="25"
                                height="25"
                                viewBox="0 0 25 25"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{fill : "#bcbcbf"}}>
                                <path
                                    d="M5.20833 11.563L7.29167 9.47969L13.0208 15.2089L16.6667 11.563L19.7917 14.688V5.20833H5.20833V11.563ZM5.20833 14.5093V19.7917H8.43802L11.5477 16.682L7.29167 12.4259L5.20833 14.5093ZM11.3843 19.7917H19.7917V17.6343L16.6667 14.5093L11.3843 19.7917ZM4.16667 3.125H20.8333C21.4086 3.125 21.875 3.59137 21.875 4.16667V20.8333C21.875 21.4086 21.4086 21.875 20.8333 21.875H4.16667C3.59137 21.875 3.125 21.4086 3.125 20.8333V4.16667C3.125 3.59137 3.59137 3.125 4.16667 3.125ZM16.1458 10.4167C15.2829 10.4167 14.5833 9.71711 14.5833 8.85417C14.5833 7.99122 15.2829 7.29167 16.1458 7.29167C17.0087 7.29167 17.7083 7.99122 17.7083 8.85417C17.7083 9.71711 17.0087 10.4167 16.1458 10.4167Z"
                                    fill="#8F8F99"
                                />
                            </svg>
                            <p style={{marginBottom : "0"}}>회차 리스트</p>
                        </div>
                        <div className={createNovel5Css.list_group + " list_group list-group"}>
                            <div className={createNovel5Css.list + " list"} onClick={onClickModify}>
                                <div className={createNovel5Css.title + " title"}>작품 정보 수정</div>
                            </div>
                            <div className={createNovel5Css.list + " list"} onClick={() => {onClickDeleteNovel(novelId)}}>
                                <div className={createNovel5Css.title + " title"}>작품 삭제</div>
                            </div>
                            <div className={createNovel5Css.list + " list"} onClick={onClickNewEpisode}>
                                <div className={createNovel5Css.title + " title"}>+ 새로운 회차쓰기</div>
                            </div>
                            {episodeList && episodeList.map((epi) => {return(<div className={createNovel5Css.list + " list"} key={"epi" + epi?.id} onClick={() => {onClickUrl(epi?.id)}}>
                                <div className={createNovel5Css.title + " title"}>{epi.chapter}화</div>
                            </div>)})}
                        </div>
                    </div>
                </div>
            </LeftAsideContentDiv> : <></>}
        </>
    );
};
export default CreateNovelLeftAside;


/** div 태그 .leftAside .content css */
const LeftAsideContentDiv = styled.div`
    padding: 54px 40px 0 40px;
`;

/** div 태그 .leftAside .top css */
const LeftAsideTopDiv = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
`;

/** div 태그 .nav-menu css */
const NavMenuDiv = styled.div`
    margin-top: 46px;
    display: none;
`;

/** div 태그 .list-group css */
const ListGroupDiv = styled.div`
    font-family: "Min Sans-Regular";
`;

/** a 태그 .list css 
const ListALink = styled(Link)`
    display: flex;
    color: #fff;
    border-bottom: 1px solid #2c2c34;
    padding: 12px 0 12px 20px;
    cursor: pointer;
    &:first-of-type {
        border-top: 1px solid #2c2c34;
    }
    &:last-of-type {
        margin-bottom: 42px;
    }
    &:hover {
        color: #ff5f37;
    }
`;*/

/** div 태그 .cate css 
const CateDiv = styled.div`
    padding-right: 4px;
`;*/

/** div 태그 .profile css */
const ProfileDiv = styled.div`
    border: 1px solid #464651;
    border-radius: 12px;
    padding: 20px;
    margin-top: 46px;
    margin-bottom: 42px;
`;

/** div 태그 .topProfile css */
const TopProfileDiv = styled.div`
    color: #fff;
    display: flex;
    font-size: 18px;
    font-family: "Min Sans-bold";
    letter-spacing: -0.03em;
    padding-bottom: 24px;
    gap: 4px;
`;

/** div 태그 .bottomProfile css */
const BottomProfileDiv = styled.div`
    color: #ff5f37;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-family: "Min Sans-Medium";
    letter-spacing: -0.03em;
    gap: 8px;
    cursor: pointer;
`;

/** p 태그 .bottomProfile .right css */
const BottomProfileRightP = styled.div`
    text-align: right;
    text-decoration: underline;
    margin-bottom: 0;
`;

/** div 태그 .coin css */
const CoinDiv = styled.div`
    display: flex;
    justify-content: space-between;
`;

/** div 태그 .my .tit css */
const MyTitDiv = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 12px;
`;

/** p 태그 .tit p css */
const TitPP = styled.p`
    font-size: 22px;
    font-family: "Min Sans-bold";
    letter-spacing: -0.03em;
    color: #8f8f99;
    margin-left: 4px;
    margin-bottom: 0;
`;

/** div 태그 .list css 
const ListDiv = styled.div`
    display: flex;
    color: #fff;
    border-bottom: 1px solid #2c2c34;
    padding: 12px 0 12px 20px;
    cursor: pointer;
    &:hover {
        color: #ff5f37;
    }
    &:first-of-type {
        border-top: 1px solid #2c2c34;
    }
    &:last-of-type {
        margin-bottom: 42px;
    }
`;*/